.bannerContent {
    text-align: center;
    background-image: url('../../assets/yellow_banner\ 2.png');
    height: auto;
    color: #501781;
    padding: 40px 250px;
    width: 100%;
    
}

.heading {
    font-size: 3.8rem;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: 'BebasNeue', sans-serif;
}

.para {
    margin: 0;
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 1639px) {

    .bannerContent {
        padding-left: 200px;
        padding-right: 200px;
    }

    .heading {
        font-size: 3.4rem;
    }

    .para {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 1270px) {

    .bannerContent {
        padding-left: 170px;
        padding-right: 170px;
    }

    .heading {
        font-size: 3rem;
    }

    .para {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1115px) {
    .bannerContent {
        padding-left: 150px;
        padding-right: 150px;
    }
}

@media screen and (max-width: 988px) {
    .bannerContent {
        padding-left: 130px;
        padding-right: 130px;
    }

    .heading {
        font-size: 2.8rem;
    }

    .para {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 917px) {
    .bannerContent {
        padding-left: 110px;
        padding-right: 110px;
    }
}

@media screen and (max-width: 751px) {

    .bannerContent {
        padding-left: 90px;
        padding-right: 90px;
    }

    .heading {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 712px) {
    .heading {
        font-size: 2.3rem;
    }
}

@media screen and (max-width: 635px) {
    .para {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 560px) {
    .bannerContent {
        padding: 25px 60px;
    }
}

@media screen and (max-width: 462px) {
    .heading {
        font-size: 2rem;
    }
}

@media screen and (max-width: 409px) {
    .bannerContent {
        padding-left: 40px;
        padding-right: 40px;
    }

    .heading {
        font-size: 1.8rem;
    }

    .para {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 343px) {
    .bannerContent {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .heading {
        font-size: 1.5rem;
    }

    .para {
        font-size: 1rem;
    }
}

@media screen and (max-width: 300px) {
    .heading {
        font-size: 1.4rem;
    }

    .para {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 260px) {
    .heading {
        font-size: 1.2rem;
    }

    .para {
        font-size: 0.8rem;
    }
}