.customIndicators li {
    background-color: #ffffff; 
    border-radius: 50%; 
    width: 10px; 
    height: 10px; 
    margin: 0 4px;
}

.customIndicators .active {
    background-color: #fff; 
}

.carouselControlPrev,
.carouselControlNext {
    width: 30px;
}

.carouselControlPrev {
    margin-left: 20px;
}

.carouselControlNext {
    margin-right: 20px;
}

@media screen and (max-width: 560px) {
    .carouselControlPrev {
        margin-left: 0;
    }
    
    .carouselControlNext {
        margin-right: 0;
    }
}