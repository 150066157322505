.FL_Binss {
  gap: 30px;
  margin: 50px auto;
  max-width: 1600px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-top: 110px;
}

.imageContainer {
  cursor: pointer;
  position: relative;
}

.imageWrapper {
  position: relative;
}

.image1 {
  height: 100px;
}

.image2 {
  height: 110px;
  width: 150px;
}

.image3 {
  height: 100px;
  margin-right: 5px;
  width: 150px;
}

.image4 {
  height: 130px;
  width: 160px;
}

.image5 {
  height: 124px;
}

.image6 {
  height: 140px;
}

.verticalLine_2yard {
  position: absolute;
  left: 160px;
  top: 13px;
  height: 80px;
  opacity: 0.7;
}

.horizontalLine_2yard {
  position: absolute;
  top: -40px;
  left: 18px;
  width: 110px;
  opacity: 0.7;
}

.depth_2yard {
  position: absolute;
  top: 95px;
  left: -38px;
  width: 50px;
  opacity: 0.7;
}

.verticalLine_4yard {
  position: absolute;
  left: 170px;
  top: 13px;
  height: 90px;
  opacity: 0.7;
}

.horizontalLine_4yard {
  position: absolute;
  top: -40px;
  left: 20px;
  width: 114px;
  opacity: 0.7;
}

.depth_4yard {
  position: absolute;
  top: 107px;
  left: -40px;
  width: 50px;
  opacity: 0.7;
}

.verticalLine_6yardSlant {
  position: absolute;
  left: 175px;
  top: 13px;
  height: 82px;
  opacity: 0.7;
}

.horizontalLine_6yardSlant {
  position: absolute;
  top: -40px;
  left: 20px;
  width: 116px;
  opacity: 0.7;
}

.depth_6yardSlant {
  position: absolute;
  top: 99px;
  left: -41px;
  width: 50px;
  opacity: 0.7;
}

.verticalLine_6yardTall {
  position: absolute;
  left: 176px;
  top: 13px;
  height: 105px;
  opacity: 0.7;
}

.horizontalLine_6yardTall {
  position: absolute;
  top: -33px;
  left: 9px;
  width: 142px;
  opacity: 0.7;
}

.depth_6yardTall {
  position: absolute;
  top: 124px;
  left: -36px;
  width: 50px;
  opacity: 0.7;
}

.verticalLine_8yardSlant {
  position: absolute;
  left: 186px;
  top: 19px;
  height: 96px;
  opacity: 0.7;
}

.horizontalLine_8yardSlant {
  position: absolute;
  top: -40px;
  left: 23px;
  width: 125px;
  opacity: 0.7;
}

.depth_8yardSlant {
  position: absolute;
  top: 122px;
  left: -37px;
  width: 50px;
  opacity: 0.7;
}

.verticalLine_8yardTall {
  position: absolute;
  left: 165px;
  top: 13px;
  height: 120px;
  opacity: 0.7;
}

.horizontalLine_8yardTall {
  position: absolute;
  top: -30px;
  left: 13px;
  width: 130px;
  opacity: 0.7;
}

.depth_8yardTall {
  position: absolute;
  top: 136px;
  left: -32px;
  width: 50px;
  opacity: 0.7;
}

.serviceContainer {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.textContainer {
  margin-top: 15px;
}

.title {
  font-family: "BebasNeue", sans-serif;
  font-size: 2rem;
  text-align: center;
  width: 80%;
  margin: 0 auto;
  line-height: 1.2;
}

.text {
  font-family: "Poppins", sans-serif;
  font-size: 1.4rem;
  text-align: center;
}

.bold {
  color: #501781;
  font-family: "PoppinsBold", "sans-serif";
  font-weight: 700;
  margin-left: 5px;
}

@media screen and (max-width: 1850px) {
  .FL_Binss {
    width: 77%;
  }

  .image1 {
    height: 100px;
  }

  .image2 {
    height: 110px;
    width: 150px;
  }

  .image3 {
    height: 100px;
    margin-right: 5px;
    width: 150px;
  }

  .image4 {
    height: 127px;
  }

  .image5 {
    height: 124px;
  }

  .image6 {
    height: 140px;
  }

  .verticalLine_2yard {
    top: 20px;
    height: 68px;
    /* left: -33px; */
    left: 159px;
  }

  .horizontalLine_2yard {
    left: 19px;
    width: 108px;
    top: -30px;
  }

  .depth_2yard {
    position: absolute;
    top: 96px;
    left: -40px;
    width: 50px;
    opacity: 0.7;
  }

  .verticalLine_4yard {
    top: 21px;
    height: 78px;
    /* left: -40px; */
    left: 160px;
  }

  .horizontalLine_4yard {
    width: 113px;
    top: -30px;
  }

  .depth_4yard {
    position: absolute;
    top: 107px;
    left: -40px;
    width: 50px;
    opacity: 0.7;
  }

  .verticalLine_6yardSlant {
    top: 20px;
    height: 72px;
    /* left: -41px; */
    left: 170px;
  }

  .horizontalLine_6yardSlant {
    width: 117px;
    left: 19px;
  }

  .depth_6yardSlant {
    position: absolute;
    top: 99px;
    left: -41px;
    width: 50px;
    opacity: 0.7;
  }

  .verticalLine_6yardTall {
    top: 13px;
    height: 99px;
    left: 179px;
  }

  .horizontalLine_6yardTall {
    left: 9px;
    width: 142px;
  }

  .depth_6yardTall {
    position: absolute;
    top: 122px;
    left: -36px;
    width: 50px;
    opacity: 0.7;
  }

  .verticalLine_8yardSlant {
    top: 25px;
    height: 91px;
    /* left: -50px; */
    left: 191px;
  }

  .horizontalLine_8yardSlant {
    left: 23px;
    width: 123px;
  }

  .depth_8yardSlant {
    position: absolute;
    top: 122px;
    left: -38px;
    width: 50px;
    opacity: 0.7;
  }

  .verticalLine_8yardTall {
    top: 16px;
    height: 113px;
    left: 175px;
  }

  .horizontalLine_8yardTall {
    left: 14px;
    width: 130px;
  }

  .depth_8yardTall {
    position: absolute;
    top: 138px;
    left: -32px;
    width: 50px;
    opacity: 0.7;
  }
}

@media screen and (max-width: 1450px) {
  .FL_Binss {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    row-gap: 60px;
    column-gap: 150px;
  }

  .image1 {
    margin-left: 11px;
    width: 138px;
  }

  .image4 {
    height: 127px;
    margin-left: 2px;
  }

  .verticalLine_2yard {
    top: 18px;
    height: 70px;
    left: 160px;
  }

  .horizontalLine_2yard {
    left: 23px;
    left: 30px;
    width: 104px;
    top: -30px;
  }

  .depth_2yard {
    position: absolute;
    top: 96px;
    left: -31px;
    width: 50px;
    opacity: 0.7;
  }

  .image2 {
    margin-left: 10px;
    height: 108px;
  }

  .verticalLine_4yard {
    top: 22px;
    height: 75px;
    left: 180px;
  }

  .horizontalLine_4yard {
    width: 115px;
    left: 30px;
    top: -30px;
  }

  .depth_4yard {
    position: absolute;
    top: 105px;
    left: -29px;
    width: 50px;
    opacity: 0.7;
  }

  .image3 {
    margin-left: 2px;
  }

  .horizontalLine_6yardSlant {
    width: 116px;
    left: 21px;
    top: -30px;
  }

  .verticalLine_6yardSlant {
    top: 18px;
    height: 83px;
    left: 180px;
  }

  .depth_6yardSlant {
    position: absolute;
    top: 99px;
    left: -39px;
    width: 50px;
    opacity: 0.7;
  }

  .verticalLine_6yardSlant {
    left: 176px;
    height: 73px;
  }

  .horizontalLine_6yardTall {
    left: 11px;
    width: 142px;
  }

  .verticalLine_6yardTall {
    height: 100px;
    left: 181px;
    top: 14px;
  }
  

  .horizontalLine_8yardSlant {
    left: 23px;
    width: 123px;
    top: -30px;
  }

  .verticalLine_8yardSlant {
    top: 25px;
    height: 95px;
    left: 190px;
  }

  .horizontalLine_8yardTall {
    left: 14px;
    width: 130px;
  }

  .verticalLine_8yardTall {
    top: 20px;
    height: 110px;
    left: 175px;
  }

  .serviceContainer {
    width: 270px;
  }
}

@media screen and (max-width: 1250px) {
  .FL_Binss {
    row-gap: 55px;
    column-gap: 140px;
  }

  .serviceContainer {
    width: 250px;
  }

  .image {
    height: 230px;
  }

  .title {
    font-size: 1.7rem;
  }

  .text {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1150px) {
  .FL_Binss {
    gap: 0;
    margin: 40px auto;
    row-gap: 50px;
    column-gap: 130px;
  }

  .title {
    font-size: 1.5rem;
  }

  .depth_6yardSlant {
    position: absolute;
    top: 107px;
    left: -32px;
    width: 50px;
    opacity: 0.7;
  }
}

@media screen and (max-width: 1060px) {
  .FL_Binss {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    justify-content: center;
    row-gap: 60px;
    column-gap: 130px;
    margin-top: 80px;
  }

  .image1 {
    margin-left: 2px;
    width: 124px;
  }

  .verticalLine_2yard {
    top: 22px;
    height: 64px;
    left: 171px;
  }

  .horizontalLine_2yard {
    left: 37px;
    width: 94px;
  }

  .depth_2yard {
    position: absolute;
    top: 96px;
    left: -22px;
    width: 50px;
    opacity: 0.7;
  }

  .image2 {
    margin-left: 4px;
  }

  .verticalLine_4yard {
    top: 19px;
    height: 76px;
    left: 177px;
  }

  .horizontalLine_4yard {
    width: 114px;
    left: 24px;
    top: -30px;
  }

  .depth_4yard {
    position: absolute;
    top: 105px;
    left: -37px;
    width: 50px;
    opacity: 0.7;
  }

  .image3 {
    margin-left: 7px;
  }

  .horizontalLine_6yardSlant {
    left: 26px;
    width: 117px;
  }

  .verticalLine_6yardSlant {
    top: 20px;
    height: 72px;
    left: 185px;
  }

  .image4 {
    height: 127px;
    margin-left: -13px;
  }

  .horizontalLine_6yardTall {
    left: 8px;
    width: 140px;
  }

  .verticalLine_6yardTall {
    height: 102px;
    left: 174px;
    top: 14px;
  }

  .depth_6yardSlant {
    position: absolute;
    top: 99px;
    left: -34px;
    width: 50px;
    opacity: 0.7;
  }

  .depth_6yardTall {
    position: absolute;
    top: 124px;
    left: -41px;
    width: 50px;
    opacity: 0.7;
  }

  .horizontalLine_8yardSlant {
    left: 23px;
    width: 123px;
  }

  .verticalLine_8yardSlant {
    top: 25px;
    height: 90px;
    left: 189px;
  }

  .depth_8yardSlant {
    position: absolute;
    top: 123px;
    left: -38px;
    width: 50px;
    opacity: 0.7;
  }

  .horizontalLine_8yardTall {
    left: 15px;
    width: 128px;
  }

  .verticalLine_8yardTall {
    top: 20px;
    height: 110px;
    left: 170px;
  }

  .serviceContainer {
    width: 270px;
  }

  .image {
    height: 230px;
  }
}

@media screen and (max-width: 560px) {
  .FL_Binss {
    display: grid;
    grid-template-columns: repeat(1, auto);
    justify-content: center;
    column-gap: 100px;
  }

  .image1 {
    margin-left: 3px;
    width: 113px;
  }
  .horizontalLine_2yard {
    left: 38px;
    width: 84px;
  }

  .verticalLine_2yard {
    left: 160px;
    height: 71px;
    top: 16px;
  }

  .depth_2yard {
    position: absolute;
    top: 97px;
    left: -20px;
    width: 50px;
    opacity: 0.7;
  }

  .image2 {
    margin-left: 13px;
    width: 131px;
  }

  .horizontalLine_4yard {
    left: 31px;
    width: 99px;
  }

  .verticalLine_4yard {
    left: 171px;
  }

  .depth_4yard {
    position: absolute;
    top: 107px;
    left: -28px;
    width: 50px;
    opacity: 0.7;
  }

  .image3 {
    margin-left: 2px;
  }

  .image4 {
    margin-left: -8px;
    height: 134px;
    width: 151px;
  }

  .horizontalLine_6yardSlant {
    left: 21px;
    width: 116px;
  }

  .depth_6yardSlant {
    position: absolute;
    top: 99px;
    left: -30px;
    width: 50px;
    opacity: 0.7;
  }

  .verticalLine_6yardTall {
    left: 178px;
    height: 108px;
  }

  .horizontalLine_6yardTall {
    left: 12px;
    width: 131px;
  }

  .verticalLine_6yardSlant {
    left: 180px;
  }

  .depth_6yardSlant {
    position: absolute;
    top: 99px;
    left: -40px;
    width: 50px;
    opacity: 0.7;
  }

  .depth_6yardTall {
    position: absolute;
    top: 128px;
    left: -37px;
    width: 50px;
    opacity: 0.7;
  }

  .image5 {
    margin-left: -5px;
  }
  .verticalLine_8yardSlant {
    left: 190px;
  }

  .horizontalLine_8yardSlant {
    width: 122px;
    left: 18px;
  }

  .depth_8yardSlant {
    position: absolute;
    top: 122px;
    left: -44px;
    width: 50px;
    opacity: 0.7;
  }

  .verticalLine_8yardTall {
    left: 180px;
  }

  .serviceContainer {
    width: auto;
  }

  .title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
  }
}

@media screen and (max-width: 390px) {
  .FL_Binss {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 57px;
  }

  .serviceContainer {
    width: auto;
  }

  .title {
    font-size: 1.4rem;
  }

  .text {
    width: auto;
  }

  .image1 {
    margin-left: -16px;
    width: 64px;
    height: 50px;
  }

  .verticalLine_2yard {
    top: 10px;
    height: 35px;
    left: 77px;
  }

  .horizontalLine_2yard {
    left: 11px;
    width: 48px;
    top: -20px;
  }

  .depth_2yard {
    position: absolute;
    top: 49px;
    left: -24px;
    width: 30px;
    opacity: 0.7;
  }

  .image2 {
    margin-left: -1px;
    height: 58px;
    width: 71px;
  }

  .verticalLine_4yard {
    top: 12px;
    height: 41px;
    left: 83px;
  }

  .horizontalLine_4yard {
    width: 54px;
    top: -21px;
    left: 8px;
  }

  .depth_4yard {
    position: absolute;
    top: 56px;
    left: -27px;
    width: 30px;
    opacity: 0.7;
  }

  .image3 {
    margin-left: 3px;
    height: 50px;
    width: 84px;
  }

  .horizontalLine_6yardSlant {
    width: 64px;
    top: -25px;
    left: 14px;
  }

  .verticalLine_6yardSlant {
    top: 11px;
    height: 35px;
    left: 99px;
  }

  .depth_6yardSlant {
    position: absolute;
    top: 51px;
    left: -23px;
    width: 30px;
    opacity: 0.7;
  }

  .image4 {
    height: 72px;
    width: 81px;
    margin-left: -11px;
  }

  .horizontalLine_6yardTall {
    left: 4px;
    width: 71px;
    top: -25px;
  }

  .verticalLine_6yardTall {
    top: 7px;
    height: 58px;
    left: 91px;
  }

  .depth_6yardTall {
    position: absolute;
    top: 69px;
    left: -25px;
    width: 30px;
    opacity: 0.7;
  }

  .image5 {
    margin-left: -2px;
    height: 70px;
  }

  .horizontalLine_8yardSlant {
    left: 11.5px;
    width: 68.5px;
    top: -25px;
  }

  .verticalLine_8yardSlant {
    top: 10px;
    height: 55px;
    left: 106px;
  }

  .depth_8yardSlant {
    position: absolute;
    top: 70px;
    left: -26px;
    width: 30px;
    opacity: 0.7;
  }

  .image6 {
    height: 90px;
    margin-left: -3px;
  }

  .horizontalLine_8yardTall {
    left: 7.5px;
    width: 81px;
    top: -25px;
  }

  .verticalLine_8yardTall {
    top: 13px;
    height: 72px;
    left: 110px;
  }

  .depth_8yardTall {
    position: absolute;
    top: 89px;
    left: -22px;
    width: 30px;
    opacity: 0.7;
  }

  .text {
    font-size: 0.7rem;
  }
}
