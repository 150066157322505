


.mainBanner {
    width: 100%;
    margin: 0 auto;
    background-size: cover;
    background-position: center; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}

.mainBannerAdditional::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
}

.mainBannerContent{
    margin: 80px 150px;
    max-width: 35%;
    color: white;
}

.mainBannerContent1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 50px;
    margin-right: 50px; 
    max-width: 35%;
    color: white;
    position: absolute;
    bottom: 0;
    right: 0; 
}


.mainBannerContentAdditional {
    max-width: 26%;
}

.heading {
    font-size: 3.8rem;
    font-weight: lighter;
    margin-bottom: 10px;
    font-family: "BebasNeue", sans-serif;
    color: white;
}

.text {
    font-size: 1.8rem;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.mainBannerContent {
    position: absolute;
}

.mainBannerContent1 {
    position: absolute;
}


.image {
    width: 100%;
}


@media screen and (max-width: 1639px) {
    .heading {
        font-size: 3rem;
    }

    .text {
        font-size: 1.6rem;
    }
    
}

@media screen and (max-width: 1450px) {
    .mainBannerContent {
        max-width: 40%;
    }
    .mainBannerContentAdditional {
        max-width: 26%;
    }
}

@media screen and (max-width: 1360px) {

    .mainBannerContent {
        margin-left: 100px;
        margin-right: 100px;
    }

    .heading {
        font-size: 2.8rem;
    }

    .text {
        font-size: 1.4rem;
    }
}

@media screen and (max-width: 1280px) {
    .mainBannerContent {
        max-width: 45%;
    }

    .mainBannerContent1 {
        max-width: 45%;
    }

}



@media screen and (max-width: 988px) {
    .mainBannerContent {
        margin-left: 80px;
        margin-right: 80px;
    }

    .mainBannerContent1{
        margin-bottom: 40px;
        margin-right: 40px; 

    }

    .heading {
        font-size: 2.8rem;
    }

    .text {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 917px) {
    .mainBanner {
        /* min-height: 460px; */
    }

    .mainBannerContent {
        max-width: 50%;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .mainBannerContent1{
        max-width: 50%;
        margin-bottom: 30px;
        margin-right: 30px; 
    }

    .mainBannerContent1{
        max-width: 50%;
    }
}

@media screen and (max-width: 826px) {
    .mainBannerContent {
        max-width: 55%;
    }


    .mainBannerContent1{
        max-width: 55%;
    }
}

@media screen and (max-width: 751px) {
    .mainBannerContent {
        max-width: 58%;
    }

    .mainBannerContent1{
        max-width: 58%;
    }

    .heading {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 712px) {
    .mainBanner {
        /* min-height: 420px; */
    }

    .mainBannerContent {
        max-width: 65%;
    }

    .mainBannerContent1{
        max-width: 65%;
    }

    .heading {
        font-size: 2.3rem;
    }
}

@media screen and (max-width: 635px) {
    .text {
        font-size: 1.2rem;
   
    }
}

@media screen and (max-width: 600px) {
    .mainBanner {
        position: relative;
    }


    .mobileBannerAdditional {
        position: relative;
    }
    .mobileBannerAdditional::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
    }
    .mainBannerContent {
        position: absolute;
    }
    .mainBannerContent1 {
        position: absolute;
    }
    
    .image {
        width: 100%;
    }

    .residentialText {
        position: absolute;
        bottom:0px;
        right: 0px;
        color: white;
        max-width: 80%;
        margin-bottom: 10px;
    }
    .commercialText {
        position: absolute;
        bottom: 0px;
        right: 0;
        color: #fff;
        max-width: 60%;
        margin-bottom: 5px; 
        margin-right: 7px; 
        text-align: justify;
    }
    .sustainabilityText {
        position: absolute;
        bottom: 0px;
        right: 0;
        color: white;
        max-width: 70%;
        margin-bottom: 5px;
        margin-right: 7px;
        text-align: justify;
    }
    .homeCarouselText {
        position: absolute;
        bottom: 0px;
        right: 0;
        color: white;
        max-width: 70%;
        margin-bottom: 5px;
        margin-right: 7px;
    }
}

@media screen and (max-width: 586px) {
    .mainBannerContent {
        max-width: 68%;
    }

    .mainBannerContent1{
        max-width: 68%
    }
}

@media screen and (max-width: 560px) {

    .mainBannerContent {
        max-width: 70%;
        margin-left: 60px;
        margin-right: 60px;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .mainBannerContent1{

    }
}

@media screen and (max-width:559px){
    .residentialText{
        margin-bottom: 0;
    }
}

@media screen and (max-width:552px) {
    /* .text { */
        /* text-wrap: wrap;
        font-size: 1rem;
        position: absolute; */
    /* bottom: -12rem;
    left: 25px;
    width: 26rem;
    padding-bottom: 1rem;
    padding-left: 1rem; */
    /* } */
    .mainBannerContent{
        max-width: 70%;
        margin-bottom: 10px;
    }

    .mainBannerContent1{

    }
    
}

@media screen and (max-width:517px){
    .sustainabilityText{
        max-width: 80%;
    }
}

@media screen and (max-width: 492px) and (min-width:486px){
    
}

@media screen and (max-width: 485px) {

    .mainBannerContent {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .mainBannerContent1{

    }

    .heading {
        font-size: 1.8rem;
    }
}

@media screen and (max-width:475px){

}

@media screen and (max-width:452px){
    .sustainabilityText{
        font-size: 1rem;
    }
}
@media screen and (max-width: 446px){
    .homeCarouselText{
        font-size: 1rem;
    }
}

@media screen and (max-width: 414px) {

    .mainBannerContent {
        margin-left: 40px;
        margin-right: 40px;
        max-width: 100%;
    }

    .mainBannerContent1{

    }
    .residentialText{
        font-size: 1rem;
    }
}
@media screen and (max-width:395px){

}

@media screen and (max-width: 380px) {

    .mainBannerContent {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .mainBannerContent1{

    }

    .heading {
        font-size: 1.5rem;
    }

    .text {
        font-size: 0.9rem;
    }
}

@media screen and (max-width:339px){
    .sustainabilityText{
        max-width: 90%;
    }
}
@media screen and (max-width: 321px) {
    .mainBannerContent {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .mainBannerContent1{

    }

    .heading {
        margin-bottom: 5px;
        font-size: 1.4rem;
    }

}

@media screen and (max-width: 304px) {
    .mainBannerContent {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .mainBannerContent1{

    }

    .image {
        height: 228px;
    }

    .heading {
        font-size: 1.3rem;
    }

    .text {
        font-size: 0.8rem;
    }
}

@media screen and (max-width:295px){

}





