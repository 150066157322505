.wing{
    position:absolute;
    top: 15px;
    right: 22px;
    z-index: -1000;
}
.wing img{
    width: 60px;
}

.wingBack {
    position: absolute;
    bottom: 0;
    left:0;
    transform: rotate(-25deg);
    transition: transform .5s ease-in-out, opacity .5s ease-in-out;
    transform-origin: 0% 100%;
    
}

.wingFront {
    position: absolute;
    transform: rotate(-5deg);
    bottom: 0;
    left:0;
    opacity: .7;
    transform-origin: 0% 100%;
    transition: transform .5s ease-in-out, opacity .8s ease-in-out;
}

.wingBackHovered  {
    transform: rotate(-22deg);
    transition: transform 1s ease-in-out;
    cursor: pointer;
}

.wingFrontHovered{
    transform: rotate(-18deg);
    transition: transform 1s ease-in-out;
    cursor: pointer;
}


@media screen and (max-width: 1450px) {
    .wing{
        top: 15px;
        left: 95px;
        z-index: -1000;

    }
    .wing img{
        width: 60px
    }
    
}

@media screen and (max-width: 1070px) {
    .wing{
        top: 12px;
        left: 120px;
        z-index: -1000;

    }
    .wing img{
        width: 60px
    }
    
}

@media screen and (max-width: 693px) {
    .wing{
        top: 12px;
        left: 120px;
        z-index: -1000;

    }
    .wing img{
        width: 60px
    }
    
}

@media screen and (max-width: 560px) {
    .wing{
        top: 12px;
        left: 115px;
        z-index: -1000;

    }
    .wing img{
        width: 60px
    }
    
}



@media screen and (max-width: 390px) {
    .wing{
        top: 18px;
        left: 40px;
        z-index: -1000;

    }
    .wing img{
        width: 60px
    }
    
}