.bins {
    margin: 100px auto;
    max-width: 1400px;
    display: flex;
    align-items: flex-end;
    gap: 20%;
}

.imageContainer {
    position: relative;
    cursor: pointer;
}

.image1 {
    height: 270px;
}

.image2 {
    height: 370px;
}

.text {
    text-align: center;
    font-size: 1.8rem;
    font-family: 'Poppins', 'sans-serif';
    margin-top: 15px;
}

.bold {
    color: #501781;
    font-family: 'PoppinsBold', 'sans-serif';
    font-weight: 700;
}

@media screen and (max-width: 1850px) {
    .bins {
        width: 77%;
    }
}

@media screen and (max-width: 1440px) {
    .bins {
        width: 75%;
    }

    .image1 {
        height: 250px;
    }

    .image2 {
        height: 350px;
    }

    .text {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 1340px) {
    .bins {
        width: 73%;
        gap: 22%;
    }

    .image1 {
        height: 230px;
    }

    .image2 {
        height: 310px;
    }
}

@media screen and (max-width: 1180px) {
    .bins {
        justify-content: center;
    }
}

@media screen and (max-width: 728px) {
    .bins {
        gap: 18%;
    }

    .image1 {
        height: 190px;
    }

    .image2 {
        height: 260px;
    }
}

@media screen and (max-width: 560px) {
    .bins {
        flex-direction: column;
        align-items: center;
        gap: 50px;
    }

    .text {
        font-size: 1rem;
    }
}

@media screen and (max-width: 390px) {
    .image1 {
        height: 160px;
    }

    .image2 {
        height: 220px;
    }
}

@media screen and (max-width: 340px) {
    .bins {
        gap: 40px;
        margin: 50px auto;
    }

    .image1 {
        height: 110px;
    }

    .image2 {
        height: 150px;
    }

    .text {
        font-size: 0.9rem;
    }
}