.mainContainer {
  display: flex;
  margin: 2.5rem 5rem;
  flex-direction: row;
  align-items: center;
  background-color: white;
  justify-content: space-between;
}

.mapimg {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  height: 100%;
  max-width: 50%;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
  padding-bottom: 20px;
}

.mapimg img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desc {
  padding: 2rem 3rem;
  align-items: center;
  height: 100%;
  flex-wrap: wrap;
  max-width: 55%;
}

.desc p {
  font-size: 1.5rem;
  /* text-align: justify;
  word-spacing: -3px; */
  font-family: 'Poppins', 'sans-serif';
  font-weight: 400;
  word-wrap: break-word;
}

.space {
  padding-bottom: 0.5rem;
}



.desc h2 {
  color: #43832a;
  font-family: 'BebasNeue', sans-serif;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.05;
  padding-bottom: 1rem;
  margin: 0;
  word-spacing: 2px;
}

.wingContact {
  position: absolute;
  top: 50%;
  right: -5%;
  width: 30%;
}

/* @media screen and (max-width:0px) and */

@media screen and (max-width:550px) {
  .mainContainer {
    flex-direction: column;
    padding: 0 1rem;
    margin: auto;
  }

  .mapimg {
    max-width: max-content;
  }

  .desc {
    max-width: 100%;
  }

  .desc p {
    font-size: 0.9rem;
    /* word-spacing: -3px; */
  }

  .desc h2 {
    font-size: 1.2rem;
  }

  .wingContact img {
    width: 45%;
  }
  .wingContact {
    width: 35%;
  }

  .wingContact {
  position: absolute;
  top: 54%;
  right: -5%;
  width: 30%;
}

}

@media screen and (max-width: 400px){
  .wingContact {
    top: 56%;
    right: -5%;
  }
}

@media screen and (max-width: 610px) and (min-width:550px){
  .wingContact { 
     top: 56%;
    right: 1%;
   
  }
}

@media screen and (max-width: 768px) and (min-width:551px) {
  .mainContainer {
    display: flex;
    flex-direction: column;

  }

  .desc {
    max-width: 100%;
  }

  .desc p {
    font-size: 1.5rem;
  }

  .desc h2 {
    font-size: 2.5rem;
  }

  .map {
    max-width: 100%;
  }

  .wingContact img{
    max-width: 70%;
  }
  .wingContact {
    width: 32%;
  }
  .wingContact {
    top: 56%;
    right: -5%;
  }
}

@media screen and (max-width: 1000px) and (min-width:769px) {
  .mainContainer {
    padding: 3rem 3rem;
  }

  .desc p {
    font-size: 1.4rem;
  }

  .desc h2 {
    font-size: 2.5rem;
  }

  .map {
    max-width: 100%;
  }

  .wingContact img{
    max-width: 70%;
  }
  .wingContact {
    width: 35%;
  }
}

@media screen and (max-width:1200px) and (min-width:1001px) {

  .desc p {
    font-size: 1.6rem;
    /* word-spacing: -3px;
    text-align: justify; */
  }

  .desc h2 {
    font-size: 3.4rem;
  }

  .wingContact {
    width: 50%;
  }
}

@media screen and (min-width:1851px) {
  .mainContainer {
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
  }
}