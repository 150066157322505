@keyframes popup {
   from{
    transform: translate3d(0px, 20px, 10px);
    padding-top: 25px;
   }

   /* to{
    transform: translate3d(0px, -20px, 10px);
   } */
}


.header {
    /* display: flex;
    justify-content: space-between; */
    /* margin: 10px 40px 0 40px; */
    width: 100%;

}

.wrapper {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 0 50px;
    padding-bottom: 20px;
    /* max-width: 2000px; */
}

.container {
}

.container img {
    /* width: 800px;    Required Changes :   Could we reduce header logo on homepage by about 30% when at full scale? It is still reading too big*/
    width: 560px;
    cursor: pointer;
}

.header ul {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 35px;
    list-style: none;
    flex-wrap: wrap;
    /* padding-right: 2rem; */

}

.link {
    font-family: 'BebasNeue', sans-serif;
    font-size: 25px;
    text-align: center;
    flex-wrap: wrap;
    /* transition: transform 0.3s ease-in, color 0.5s ease-out, padding-bottom 0.3 ease;  */
    transition: font-size 0.2s ease-out;
    position: relative; 
    /* padding-bottom: 5px; */
}

/* .link:hover { */
    /* animation: popup 0.2s ease; */
    /* transition: font-size 0.2s ease-in; */
    /* transform: translateY(-20px);  */
    /* font-size: 35px; */
    /* position: relative; */
/* } */


.header ul a {
    display: inline-block;
    text-decoration: none;
}

.header ul li a:hover {
    color:  #43832a; 
    /* padding-bottom: 10px; */
}

.active {
    color:  #43832a; 
}

.notActive {
    color:  #43832a;
}

@media screen and (max-width: 1580px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .header ul {
        margin-top: 40px;
        justify-content: flex-start;
    }

    .header li {
        font-size: 22px;
    }

    .link:hover {
        animation: none;
        transition: none;
        font-size: 22px;
    }
    .header ul li a:hover {
        color:  #43832a; 
        padding-bottom: 0;
    }

    .container {
        width: 100%;
        text-align: center;
    }
}

@media screen and (max-width: 920px) {
    .container img {
        width: 100%;
    }
}

@media screen and (max-width: 710px) {

    .wrapper {
        padding: 5px;
        padding-bottom: 20px;
    }

    .header ul {
        margin-top: 30px;
        justify-content: flex-start;
        gap: 15px;
    }

    .header li {
        font-size: 18px;
    }

    .link:hover {
        animation: none;
        transition: none;
        font-size: 18px;
    }
    .header ul li a:hover {
        color:  #43832a; 
        padding-bottom: 0;
    }

    .container {
        width: 87%;
    }
}

@media screen and (max-width: 485px) {
    .wrapper {
        padding: 5px;
        padding-bottom: 15px;
    }

    .header ul {
        margin-top: 30px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        /* text-align: right; */
    }

    .header ul li {
        font-size: 20px;
    }

    .link:hover {
        animation: none;
        transition: none;
        font-size: 20px;
    }
    .header ul li a:hover {
        color:  #43832a; 
        padding-bottom: 0;
    }

    .container img {
        margin-top: 5px;
    }
}

/* @media screen and (min-width: 300px) and (max-width: 500px) {
    .header {
        align-items: center;
    }
} */
 
@media screen and (max-width: 250px) {
    .container img {
        width: 150px;
        margin-top: 0;
    }
    .header ul {
        margin-top: 20px;
    }
}