.totes {
    margin: 100px auto;
    margin-top: 150px;
    max-width: 1600px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* margin-top: 110px; */
}

.imageContainer {
    cursor: pointer;
    position: relative;
}

.image {
    height: 140px;

}

.image3 {
    height: 200px;
    width: 420px;
    
}

.image3_verticalLine_line {

    position: absolute;
    right: -30px;
    top: 18px;
    height: 167px;
    opacity: 0.7;
    
}

.image3_depth_yard {
    position: absolute;
    top: 187px;
    left: -74px;
    width: 85px;
    opacity: 0.7;
  }


.depth_yard {
    position: absolute;
    top: 132px;
    left: -74px;
    width: 85px;
    opacity: 0.7;
  }

.verticalLine_line {
    position: absolute;
    right: -30px;
    top: 13px;
    height: 115px;
    opacity: 0.7;
}

.horizontalLine_14yard {
    position: absolute;
    top: -48px;
    left: 13px;
    width: 315px;
    opacity: 0.7;
}

.horizontalLine_20yard {
    position: absolute;
    top: -52px;
    left: 13px;
    width: 375px;
    opacity: 0.7;
}

.horizontalLine_40yard {
    position: absolute;
    top: -60px;
    left: 10px;
    width: 440px;
    opacity: 0.6;
}

.text {
    text-align: center;
    font-size: 1.8rem;
    font-family: 'Poppins', 'sans-serif';
    margin-top: 15px;
}

.bold {
    color: #501781;
    font-family: 'PoppinsBold', 'sans-serif';
    font-weight: 700;
}

@media screen and (max-width: 1850px) {
    .totes {
        width: 85%;
    }
}

@media screen and (max-width: 1750px) {
    .image {
        height: 130px;
    }

    .image3 {
        height: 190px;
        width: 390px;
    }

    .image3_verticalLine_line {
        position: absolute;
        right: -30px;
        top: 18px;
        height: 157px;
        opacity: 0.7;
    }

    .image3_depth_yard {
        position: absolute;
        top: 178px;
        left: -70px;
        width: 83px;
        opacity: 0.7;
    }
    

    .verticalLine_line{
        height: 100px;
    }

    .depth_yard {
        position: absolute;
        top: 122px;
        left: -73px;
        width: 83px;
        opacity: 0.7;
    }
    .horizontalLine_14yard {
        width: 290px;
        top: -45px;
    }
    .horizontalLine_20yard {
        width: 353px;
        top: -52px;
    }
    .horizontalLine_40yard {
        width: 415px;
        top: -57px;
    }
}

@media screen and (max-width: 1640px) {
    .image {
        height: 120px;
    }

    .image3 {
        height: 180px;
        width: 360px;
    }

    .image3_verticalLine_line {
        position: absolute;
        right: -36px;
        top: 18px;
        height: 148px;
        opacity: 0.7;   
    }

    .image3_depth_yard {
        position: absolute;
        top: 169px;
        left: -67px;
        width: 80px;
        opacity: 0.7;
    }

    .verticalLine_line{
        height: 100px;
    }

    .depth_yard {
        position: absolute;
        top: 114px;
        left: -68px;
        width: 80px;
        opacity: 0.7;
    }

    .horizontalLine_14yard {
        width: 265px;
        top: -43px;
    }
    .horizontalLine_20yard {
        width: 320px;
        top: -48px;
    }
    .horizontalLine_40yard {
        width: 385px;
        top: -55px;
    }
}

@media screen and (max-width: 1555px) {
    
    .totes {
        width: 82%;
        margin-top: 130px;
    }

    .image {
        height: 110px;
    }

    .image3 {
        height: 170px;
        width: 335px;
    }

    .image3_verticalLine_line {
        position: absolute;
        right: -39px;
        top: 18px;
        height: 138px;
        opacity: 0.7;
    }

    .image3_depth_yard {
        position: absolute;
        top: 162px;
        left: -68px;
        width: 80px;
        opacity: 0.7;
    }


    .verticalLine_line{
        height: 90px;
    }

    .depth_yard{
        position: absolute;
        top: 105px;
        left: -59px;
        width: 70px;
        opacity: 0.7;
    }

    .horizontalLine_14yard {
        width: 245px;
        top: -40px;
    }
    .horizontalLine_20yard {
        width: 295px;
        top: -45px;
    }
    .horizontalLine_40yard {
        width: 350px;
        top: -50px;
    }
}

@media screen and (max-width: 1440px) {
    .totes {
        width: 80%;
        margin-top: 120px;
    }

    .image {
        height: 100px;
    }

    .image3 {
        height: 150px;
        width: 300px;
    }

    .image3_verticalLine_line {
        position: absolute;
        right: -37px;
        top: 15px;
        height: 115px;
        opacity: 0.7;
    }

    .image3_depth_yard{
        position: absolute;
        top: 141px;
        left: -50px;
        width: 60px;
        opacity: 0.7;
    }


    .text {
        font-size: 1.5rem;
    }

    .verticalLine_line{
        height: 75px;
    }

    .depth_yard{
        position: absolute;
        top: 95px;
        left: -52px;
        width: 60px;
        opacity: 0.7;
    }
    .horizontalLine_14yard {
        width: 215px;
        top: -38px;
    }
    .horizontalLine_20yard {
        width: 265px;
        top: -42px;
    }
    .horizontalLine_40yard {
        width: 318px;
        top: -45px;
    }
}

@media screen and (max-width: 1340px) {
    .totes {
        margin-top: 100px;
    }
    
    .image {
        height: 90px;
    }

    .image3 {
        height: 150px;
        width: 270px;
    }

    .image3_verticalLine_line {
        position: absolute;
        right: -37px;
        top: 15px;
        height: 124px;
        opacity: 0.7;
    }

    .image3_depth_yard{
        position: absolute;
        top: 141px;
        left: -46px;
        width: 55px;
        opacity: 0.7;
    }


    .verticalLine_line{
        height: 70px;
    }

    .depth_yard{
        position: absolute;
        top: 86px;
        left: -50px;
        width: 55px;
        opacity: 0.7;
    }

    .horizontalLine_14yard {
        width: 195px;
        top: -33px;
    }
    .horizontalLine_20yard {
        width: 240px;
        top: -38px;
    }
    .horizontalLine_40yard {
        width: 285px;
        top: -42px;
    }
}

@media screen and (max-width: 1180px) {
    .totes {
        flex-direction: column;
        align-items: center;
        gap: 120px;
        margin-bottom: 80px;
    }

    .image {
        height: 150px;
    }

    .image3 {
        height: 240px;
        width: 449px;
    }

    .image3_depth_yard{
        position: absolute;
        top: 224px;
        left: -67px;
        width: 80px;
        opacity: 0.7;
    }

    .image3_verticalLine_line {
        position: absolute;
        right: -40px;
        top: 21px;
        height: 197px;
        opacity: 0.7;
    }


    .depth_yard{
        position: absolute;
        top: 140px;
        left: -69px;
        width: 80px;
        opacity: 0.7;
    }


    .text {
        font-size: 1.8rem;
    }

    .verticalLine_line{
        height: 120px;
        top: -53;
    }

    .horizontalLine_14yard {
        width: 330px;
        top: -40px;
    }
    .horizontalLine_20yard {
        width: 405px;
        top: -50px;
    }
    .horizontalLine_40yard {
        width: 470px;
        top: -60px;
    }
}

@media screen and (max-width: 850px) {
    .totes {
        gap: 80px;
        margin-top: 80px;
    }

    .image {
        height: 100px;
    }

    .image3 {
        height: 175px;
        width: 300px;
    }

    .image3_verticalLine_line {
        position: absolute;
        right: -37px;
        top: 18px;
        height: 140px;
        opacity: 0.7;
    }

    .image3_depth_yard{
        position: absolute;
        top: 163px;
        left: -51px;
        width: 63px;
        opacity: 0.7;
    }


    .text {
        font-size: 1.5rem;
    }

    .verticalLine_line {
        height: 80px;
        top: 11px;
    }

    .depth_yard{
        position: absolute;
        top: 96px;
        left: -51px;
        width: 63px;
        opacity: 0.7;
    }

    .horizontalLine_14yard {
        width: 220px;
        top: -36px;
    }
    .horizontalLine_20yard {
        width: 265px;
        top: -40px;
    }
    .horizontalLine_40yard {
        width: 317px;
        top: -45px;
    }
}

@media screen and (max-width: 768px) {
    .totes {
        margin-top: 110px;
    }
}

@media screen and (max-width: 560px) {
    .totes {
        gap: 60px;
        margin-bottom: 60px;
        margin-top: 90px;
    }

    .image {
        height: 70px;
    }

    .image3 {
        height: 110px;
        width: 210px;
    }

    .image3_verticalLine_line {
        position: absolute;
        right: -22px;
        top: 13px;
        height: 89px;
        opacity: 0.7;
    }

    .image3_depth_yard{
        position: absolute;
        top: 102px;
        left: -43px;
        width: 50px;
        opacity: 0.7;
    }


    .text {
        font-size: 1rem;
    }

    .verticalLine_line {
        height: 55px;
        right: -15px;
        top: 8px;
    }

    .depth_yard{
        position: absolute;
        top: 66px;
        left: -43px;
        width: 50px;
        opacity: 0.7;
    }

    .horizontalLine_14yard {
        width: 153px;
        top: -25px;
        left: 10px;
    }
    .horizontalLine_20yard {
        width: 183px;
        top: -29px;
        left: 10px;
    }
    .horizontalLine_40yard {
        width: 220px;
        top: -33px;
        left: 7px;
    }
}

@media screen and (max-width: 390px) {
    .totes {
        margin: 60px auto;
        margin-bottom: 30px;
        gap: 50px;
    }

    .image {
        height: 50px;
    }

    .image3 {
        height: 75px;
        width: 148px;
    }

    .image3_verticalLine_line {
        height: 61px;
        top: 8px;
        right: -17px;
    }

    .image3_depth_yard{
        position: absolute;
        top: 71px;
        left: -30px;
        width: 35px;
        opacity: 0.7;
    }


    .verticalLine_line {
        height: 38px;
        top: 6px;
        right: -10px;
    }

    .depth_yard{
        position: absolute;
        top: 47px;
        left: -31px;
        width: 35px;
        opacity: 0.7;
    }

    .horizontalLine_14yard {
        width: 108px;
        top: -18px;
        left: 7px;
    }
    .horizontalLine_20yard {
        width: 130px;
        top: -22px;
        left: 7px;
    }
    .horizontalLine_40yard {
        width: 156px;
        top: -24px;
        left: 5px;
    }
}

@media screen and (max-width: 300px) {
    .totes {
        gap: 40px;
    }

    .image {
        height: 40px;
    }

    .image3 {
        height: 70px;
        width: 119px;
    }

    .image3_verticalLine_line {
        position: absolute;
        right: -12px;
        top: 8px;
        height: 56px;
        opacity: 0.7;
    }

    .image3_depth_yard{
        position: absolute;
        top: 67px;
        left: -26px;
        width: 30px;
        opacity: 0.7;
    }


    .text {
        font-size: 0.9rem;
    }

    .verticalLine_linee {
        height: 35px;
        right: -18px;
        top: 5px;
    }

    .depth_yard{
        position: absolute;
        top: 40px;
        left: -27px;
        width: 30px;
        opacity: 0.7;
    }

    .horizontalLine_14yard {
        width: 85px;
        top: -16px;
        left: 5px;
    }
    .horizontalLine_20yard {
        width: 105px;
        top: -18px;
        left: 5px;
    }
    .horizontalLine_40yard {
        width: 125px;
        top: -19px;
        left: 4px;
    }
} 