.wing_tote {
    position: absolute;
    top: 30px;
    right: 60px;
    z-index: -1;
}

.wing_bin {
    position: absolute;
    top: 50px;
    right: 80px;
    z-index: -1;
}

.wingBack {
    position: absolute;
    bottom: 0;
    left:0;
    transform: rotate(-25deg);
    transition: transform .5s ease-in-out, opacity .5s ease-in-out;
    transform-origin: 0% 100%;
    
}

.wingFront {
    position: absolute;
    transform: rotate(-5deg);
    bottom: 0;
    left:0;
    opacity: .7;
    transform-origin: 0% 100%;
    transition: transform .5s ease-in-out, opacity .8s ease-in-out;
}

.wingBackHovered  {
    transform: rotate(-22deg);
    transition: transform 1s ease-in-out;
    cursor: pointer;
}

.wingFrontHovered{
    transform: rotate(-18deg);
    transition: transform 1s ease-in-out;
    cursor: pointer;
}

@media screen and (max-width: 1555px) {

    .wing_tote {
        top: 20px;
        right: 50px;
    }

    .singleWing_tote {
        width: 130px;
    }
}

@media screen and (max-width: 1340px) {

    .wing_tote {
        top: 20px;
        right: 40px;
    }

    .singleWing_tote {
        width: 115px;
    }

    .wing_bin {
        top: 40px;
        right: 60px;
    }

    .singleWing_bin {
        width: 115px;
    }
}

@media screen and (max-width: 1180px) {

    .wing_tote {
        top: 30px;
        right: 60px;
    }

    .singleWing_tote {
        width: auto;
    }
}

@media screen and (max-width: 850px) {
    .wing_tote {
        top: 20px;
        right: 40px;
    }

    .singleWing_tote {
        width: 120px;
    }
}

@media screen and (max-width: 560px) {
    .wing_tote {
        top: 20px;
        right: 30px;
    }

    .singleWing_tote {
        width: 90px;
    }
}

@media screen and (max-width: 390px) {
    .wing_tote {
        top: 10px;
        right: 20px;
    }

    .singleWing_tote {
        width: 60px;
    }

    .wing_bin {
        top: 35px;
        right: 45px;
    }

    .singleWing_bin {
        width: 90px;
    }
}

@media screen and (max-width: 340px) {
    .wing_bin {
        top: 20px;
        right: 30px;
    }

    .singleWing_bin {
        width: 60px;
    }
}