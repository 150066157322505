.tiles {
    display: flex;
    justify-content: center;
    gap: 30px;
    max-width: 1400px;
    margin: 60px auto;
}

.serviceContainer {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.image {
    height: 250px;
}

.textContainer {
    margin-top: 15px;
}

.title {
    font-family: 'BebasNeue', 'sans-serif';
    font-size: 2rem;
    text-align: center;
    width: 80%;
    margin: 0 auto;
    line-height: 1.2;
}

.text {
    font-family: 'Poppins', 'sans-serif';
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width: 1400px) {
    .tiles {
        gap: 0;
    }
}

@media screen and (max-width: 1250px) {
    .tiles {
        gap: 20px;
    }

    .serviceContainer {
        width: 250px;
    }

    .image {
        height: 230px;
    }

    .title {
        font-size: 1.7rem;
    }

    .text {
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1150px) {
    .tiles {
        gap: 0;
        margin: 40px auto;
    }

    .serviceContainer {
        width: 240px;
    }

    .image {
        height: 210px;
    }

    .title {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 1060px) {
    .tiles {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: auto auto;
        justify-content: center;
        row-gap: 40px;
        column-gap: 40px;
        
    }

    .serviceContainer {
        width: 270px;
    }

    .image {
        height: 230px;
    }
}

@media screen and (max-width: 680px) {
    .tiles {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .image {
        height: 200px;
    }

    .title {
        width: auto;
    }
}

@media screen and (max-width: 500px) {
    .image {
        height: 170px;
    }

    .serviceContainer {
        width: auto;
    }

    .title {
        font-size: 1.4rem;
    }

    .text {
        font-size: 1rem;
    }
}