 .footer {
   /* text-align: center; */
   background: url('../../assets//footer_background\ 2.png') no-repeat;
   /* background-size: 100% 100%; */
   background-size: cover;
   background-position: center;
   /* background-attachment: fixed; */
   /* height: 100vh; */

 }

 .footerContent {
   display: flex;
   justify-content: flex-start;
   align-items: flex-end;
   padding-top: 40px;
   margin: 0 150px 50px;
   color: white;
 }

 .logoContainer {
  max-width: 450px;
 }

 .logo {
  width: 100%;
   height: auto;
   cursor: pointer;
 }

 .textContainer {
  margin-left: 70px;
  min-width: 525px;
 }

 .footerPara {
   text-align: left;
   font-family: 'Poppins', sans-serif;
   font-size: 1.2rem;
   margin: 0;
 }

 .footerBottom {
   display: flex;
   justify-content: space-between;
   color: #fff;
   font-family: 'Poppins', sans-serif;
   font-size: 1rem;
   margin: 0px 75px;
 }

 .footerBottom p {
  margin: 0;
  margin-bottom: 20px;
 }

 .footerBottom a{
  color: white;
  text-decoration: none;
 }

.bold {
  font-size: 1.3rem;
  font-weight: bold;
  color: white;
}

.color{
  color: #28a745;
}

.color:hover{
  color: #28a745;
}

@media screen and (max-width: 1341px) {
  .logoContainer {
    max-width: 400px;
  }
  .textContainer {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1270px) {
  .footerContent {
      margin-left: 100px;
      margin-right: 100px;
  }
  .footerBottom {
    margin: 0px 50px;
  }
}

@media screen and (max-width: 1191px) {
  .logoContainer {
    max-width: 380px;
  }
  .footerPara {
    font-size: 1rem;
  }
  .bold {
    font-size: 1.1rem;
  }
  .footerBottom {
    font-size: 0.8rem;
  }
  .textContainer {
    min-width: 440px;
  }
}

@media screen and (max-width: 1080px) {
  .logoContainer {
    max-width: 300px;
  }
  .textContainer {
    margin-left: 40px;
  }
}

@media screen and (max-width: 988px) {
  .footerContent {
    margin-left: 80px;
    margin-right: 80px;
  }
  .footerBottom {
    margin: 0px 40px;
  }
}

@media screen and (max-width: 940px) {
  .textContainer {
    min-width: 355px;
  }
  .footerPara {
    font-size: 0.8rem;
  }
  .bold {
    font-size: 0.9rem;
  }
  .footerBottom {
    font-size: 0.7rem;
  }
  .logoContainer {
    max-width: 250px;
  }
}

@media screen and (max-width: 794px) {
  .footerContent {
    flex-direction: column;
    align-items: center;
  }

  .logoContainer {
    margin-bottom: 40px;
    max-width: 200px;
  }

  .textContainer {
    margin-left: 0;
  }
  .footerPara {
    text-align: center;
  }
}

@media screen and (max-width: 560px) {
  .footerContent {
      margin-left: 60px;
      margin-right: 60px;
      padding-top: 20px;
  }
  .textContainer {
    min-width: 310px;
  }
  .footerPara {
    font-size: 0.7rem;
  }
  .bold {
    font-size: 0.8rem;
  }
  .footerBottom {
    font-size: 0.6rem;
    flex-direction: column;
    align-items: center;
    margin: 0px 30px;
  }
  .footerBottom p:nth-child(1) {
    margin-bottom: 5px;
  }
  .footerBottom p {
    margin-bottom: 10px;
    text-align: center;
  }
}

@media screen and (max-width: 425px) {
  .footerContent {
    margin-left: 40px;
    margin-right: 40px;
    padding-top: 20px;
  }
  .footerBottom {
    margin: 0px 20px;
  }
  .logoContainer {
    max-width: 150px;
  }
  .textContainer {
    min-width: unset;
  }
}

@media screen and (max-width: 300px) {
  .footerContent {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
  }
  .footerBottom {
    margin: 0px 20px;
  }
  .logoContainer {
    max-width: 120px;
  }
  .textContainer {
    min-width: unset;
  }
  .footerPara {
    font-size: 0.6rem;
  }
  .bold {
    font-size: 0.7rem;
  }
  .textContainer {
    word-break: break-word;
  }
}