.terms_privacy{
    margin: 50px;
    padding: 50px;
    /* text-align: justify; */
    text-wrap: wrap;
    font-family: 'Poppins', 'sans-serif';
}

.priv_heading{
    font-size: 1.4rem;
    text-decoration: underline;
    font-weight: 600;
    margin-bottom: 24px;
}

 .heading{
    font-size: 1.1rem;
    font-weight: 600;
}

 .para {
    font-size: 1rem;
    font-weight: 400;
  }

  .list{
    margin-left: 28px;
  }

@media screen and (max-width: 1058px){
    .terms_privacy{
        margin:30px;
        padding:30px;
    }
    
}

@media screen and (max-width:670px){

    .terms_privacy{
        margin: 20px;
        padding: 20px;
    }
    .priv_heading {
        font-size: 1.1rem;
        margin-bottom: 20px;
    } 
    .heading {
        font-size: 0.9rem;
    }
    .para{
        font-size: 0.8rem;
    }
}

@media screen and (max-width:485px){
    .terms_privacy{
        margin: 12px;
        padding: 12px;
    }

    .priv_heading {
        margin-top: 20px;
    }
}
