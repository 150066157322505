@font-face {
    font-family: 'BebasNeue';
    src: local('BebasNeue'),
         url('./assets/fonts/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'),
         url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PoppinsBold';
    src: local('PoppinsBold'),
         url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
}