.wing{
    position:absolute;
    top: 15px;
    right: 32px;
    z-index: -1000;
}
.wing img{
    width: 60px;
    z-index: -1000;
}

.wingBack {
    position: absolute;
    bottom: 0;
    left:0;
    /* bottom: 2px; */
    /* left:-9px; */
    transform: rotate(-25deg);
    transition: transform .5s ease-in-out, opacity .5s ease-in-out;
    transform-origin: 0% 100%;
    
}

.wingFront {
    position: absolute;
    transform: rotate(-5deg);
    bottom: 0;
    left:0;
    /* bottom: 2px; */
    /* left:-9px; */
    opacity: .7;
    transform-origin: 0% 100%;
    transition: transform .5s ease-in-out, opacity .8s ease-in-out;
}

.wingBackHovered  {
    transform: rotate(-22deg);
    transition: transform 1s ease-in-out;
    cursor: pointer;
}

.wingFrontHovered{
    transform: rotate(-18deg);
    transition: transform 1s ease-in-out;
    cursor: pointer;
}
