.leftContainer,
.rightContainer
{
  display: flex;
  margin: 2.5rem 5rem;
  flex-direction: row;
  align-items: center;
  background-color: white;
  /* max-width: 1200px; */
  justify-content: space-between;
 
}


.imgContainer {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  height: 100%;
  max-width: 50%;
  align-items: center;
  justify-content: center;
  padding: 0 3rem;
}
.imgContainer img{
  max-width: 100%;
  height: auto;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.textContainer {
  padding: 2rem 3rem;    
  align-items: center;
  height:100%;
  flex-wrap: wrap;
  max-width: 50%;

}

 .textContainer p{
  font-size: 1.5rem;
  /* text-align: justify;
  word-spacing: -3px; */
  font-family: 'Poppins', sans-serif;
  font-weight: 400;

}

.textContainer h2{
  color: #43832a;
  font-family: 'BebasNeue', sans-serif;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.05;
  padding-bottom: 1rem;
  margin: 0;
  word-spacing: 2px;
}


.bold, .pipeText {
  margin-top: 2rem;
  font-size: 1.8rem;
  max-width: 100%;
  word-spacing: 1px;
  font-family: 'Poppins', 'sans-serif';
}

.bold{
  color: #501781;
  font-family: 'PoppinsBold', 'sans-serif';
}

.pipe{
 color: #ccda58;
}

@media screen and (max-width:550px) {
  .leftContainer,
  .rightContainer{
    flex-direction: column;
    padding: 0 1rem;
    margin: 20px auto;
  }

  .imgContainer{
    max-width: max-content;
  }

  .textContainer{
    max-width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 0;
  }
  .textContainer p {
    font-size: 0.9rem;
    /* word-spacing: -3px; */
  }

  .textContainer h2{
    font-size: 1.2rem;
  }

  .rightImageText strong,
  .rightImageText span {
    font-size: 1rem; 
  }
}

@media screen and (max-width: 768px) and (min-width:551px){
  .leftContainer,
  .rightContainer
  {
    display: flex;
    flex-direction: column;
    
}

  .textContainer{
      max-width: 100%;
  }

  .textContainer p {
    font-size: 1.5rem; 
  }

  .textContainer h2 {
    font-size: 2.5rem;
  }

  .rightImageText strong,
  .rightImageText span {
    font-size: 1.6rem; 
  }

  .imgContainer{
    max-width: 100%;
  }
} 

@media screen and (max-width: 1000px) and (min-width:769px) {
  .leftContainer
  .rightContainer
  {
    padding: 3rem 3rem;
  }

  .textContainer p{
      font-size: 1.4rem;
    }
    
    .textContainer h2{
      font-size: 2.5rem;
    }
    
    .rightImageText strong,
    .rightImageText span {
      font-size: 1.5rem; 
    }
    
    .imgContainer{
      max-width: 100%;
    }
  }
  
  @media screen and (max-width:1200px) and (min-width:1001px) {
      
    .textContainer p{
      font-size: 1.6rem;
      /* word-spacing: -3px;
      text-align: justify; */
  }

  .textContainer h2{
    font-size: 3.4rem;
  }

  .rightImageText strong,
  .rightImageText span {
    font-size: 1.8rem; 
  }
}

@media screen and (min-width:1851px) {
  .leftContainer,
  .rightContainer {
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
  }
}